import { ApplicationState } from "../../store/index";
import { actionCreators as SignerStore } from '../../store/Signing/SignerStore';
import { actionCreators as PublicStore } from '../../store/Common/PublicStore';
import { actionCreators as OtpStore } from '../../store/Account/OtpStore';
import { actionCreators as DownloadStore } from '../../store/Download/DownloadStore';
import { Esign } from "./Esign";
import { connect } from 'react-redux';
import { DownloadStep } from '../../Core/Common/Enums';
import { ISignFinishedResponse } from "src/Core/Domain/ViewModels/ISignFinishedResponse";

function mapStateToProps(state: ApplicationState) {
    return {
        signerData: state.signerData,
        publicData: state.publicData,
        loaderData: state.loaderData
    };
}
//temperroy fix for webpack failure need to invetigate further when we have time
const mapDispatchToProps = (dispatch: any) => {
    return {
        requestPrimaryDetails: (Id: string,callback?: () => void) => dispatch(PublicStore.requestPrimaryDetails(Id,callback)),
        requestSignatureInfo: (clientGuid: string, callback?: () => void) =>
            dispatch(SignerStore.requestSignatureInfo(clientGuid, callback)),
        requestDocumentControls: (clientGuid: string, isControlsDisabled: boolean, skipTracking?: boolean) =>
            dispatch(SignerStore.requestDocumentControls(clientGuid, isControlsDisabled, skipTracking)),
        declineSigning: (clientGuid: string, remarks: string, callback: (status: boolean) => void) => dispatch(SignerStore.declineSigning(clientGuid, remarks, callback)),
        delegationCancelled: (clientGuid: string, callback: (status: boolean) => void) => dispatch(SignerStore.delegationCancelled(clientGuid, callback)),
        assignToDelegatee: (clientGuid: string, email: string, name: string, reason: string, callback: (status: boolean) => void) =>
            dispatch(SignerStore.assignToDelegatee(clientGuid, email, name, reason, callback)),
        updateDelegatedSigner: (clientGuid: string, email: string, name: string, reason: string,  callback: (status: boolean) => void) => dispatch(SignerStore.updateDelegatedSigner(clientGuid, email, name, reason, callback)),
        sign: (signatureData: any, callback: (status: ISignFinishedResponse) => void) => dispatch(SignerStore.sign(signatureData, callback)),
        refreshToken: (clientGuid: string) => dispatch(OtpStore.refreshToken(clientGuid)),
        downloadAllDocument: (clientGuid: string, fileName: string, isSigningCompleted: boolean, downloadStep: DownloadStep) =>
            dispatch(DownloadStore.downloadAllDocument(clientGuid, fileName, isSigningCompleted, downloadStep))
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Esign);


 