
export enum ClientType {
    Undefined = 0,
    Taxpayer,
    Spouse,
    Partner,
    Partnership,
    Shareholder
}

export enum ClientTypes {
    Undefined = 0,
    Signer = 1,
    CC = 2,
    Sender = 3,
    Delegatee = 4
}

export enum EngagementType {
    None,
    E1040 = 1,
    E1065,
    E1120,
    E1120S
}

export enum DocumentStatus {
    None = 0,
    UPLOADED,
    ERROR,
    READY,
    PROCESSING,
    REVIEW,
    USERSIGNED,
    DELIVERED,
    APPROVEDFORDELIVERY,
    PREPARINGFORDELIVERY,
    DELIVERYFAILED,
    NONSUPPORTED,
    RECALLED,
    DELETED
}

export enum SignatureStatus {
    None = 0,
    ESigned,
    ManuallySigned,
    Uploaded,
    AwaitingESign,
    AwaitingUpload,
    Locked,
    MailOrFax,
    SignedAndESigned,
    AutoParsed,
    Processing,
    Delivering,
    Delivered,
    DeliveryFailed
}

export enum DefaultSenderInfoType {
    None = "None",
    Company = "Company",
    User = "User"
}

export enum SignatureType {
    None,
    ESign = 1,
    ESignWhenAllIncludedReturnsAllowed,
    ManualSign
}

export enum TransmittalDownloadOption {
    None = "None",
    IncludeTransmittalInTaxReturnPDF = "IncludeTransmittalInTaxReturnPDF",
    CreateSeparateTransmittalPDF = "CreateSeparateTransmittalPDF"
}
export enum VoucherDownloadOption {
    None = "None",
    SeparateByQuarterlyDueDates = "SeparateByQuarterlyDueDates",
    GroupInSinglePDF = "GroupInSinglePDF"
}
export enum TaxpayerAuthenticationType {
    None = "None",
    Last4ofSSN = "Last4ofSSN",
    First4ofSSN = "First4ofSSN"
}
export enum SignatureFormSelectionType {
    None = "None",
    ESign = "ESign",
    ESignWhenAllIncludedReturnsAllowed = "ESignWhenAllIncludedReturnsAllowed",
    ManualSign = "ManualSign"
}

export enum NotificationType {
    Info,
    Error,
    Success,
    Warning
}

export enum DownloadStep {
    Esign = 1,
    Download
}

export enum DatePart
{
    Date,
    Month,
    Year
}