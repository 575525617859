import * as React from 'react';
import { useState, FC } from 'react';
import { Button, Form } from 'react-bootstrap';
import { IPublicData } from '../../../../store/Common/PublicStore';
import { ILoadingData } from '../../../../store/Common/LoaderStore';
import { InfoIconSmall, PasswordLockIcon } from '../../../Svg/CustomSvgIcons';
import { ErrorState } from '../../../../Core/Domain/ViewModels/PrimaryDetails';



export type QuestionAnswerLoginProps = {
    publicData: IPublicData,
    loaderData: ILoadingData,
    validateParam: (param: string) => void;
    errorState: ErrorState;
}

export const QuestionAnswerLogin:FC <QuestionAnswerLoginProps> = (props) => {
    const [answer, setAnswer] = useState('');
    
    const handleAnswerChange = (event: any) => {
        let value = event ? event.target.value : "";
        setAnswer(value);
    }

    const onContinue = () => {
        props.validateParam(answer);
    }
    return (
        <div className="qa-login-container" >
            <div className='qa-login-box'>
                <div>
                    <PasswordLockIcon/>
                </div>
                <div className='font24 fontWeight500' style={{marginTop:'5%'}}> Question & Answer </div>

                <div className='font16 fontWeight400' style={{marginTop:'3%'}}>
                    Please answer the following <br/> security question.
                </div>

                <label htmlFor='qa-login-answer' className='font16 fontWeight700 qa-login-question' >
                    {props.publicData.primaryDetails.authenticationInfo.question}
                </label>

                <div className='qa-login-input-box'>
                        <Form.Control 
                            data-test-auto="55FE7CD6-694C-4B6B-A8C9-EDA249D4AACA"
                            autoComplete={"off"}
                            placeholder='Answer'
                            className={`${props.errorState.error ? " error" : ""}`}
                            type="text"
                            value={answer}
                            id='qa-login-answer'
                            onChange={handleAnswerChange}
                        />
                        {props.errorState.error ? 
                            <>                                
                                <div className='error-text-color font14' style={{marginTop:'2%',textAlign: 'left'}}>Incorrect Answer! You have {props.errorState.attemptsLeft} more attempts left.</div>
                                <span className='error-svg'><InfoIconSmall fill='#CC4A43'/></span>  
                            </>
                        : null}
                </div>

                <div className='qa-login-button'>
                    <Button
                        data-test-auto="1BE9FAB7-CAA7-4BB5-99A7-D4A4FE5AFCAA"
                        type="submit"
                        disabled={answer === ""}
                        onClick={onContinue}>
                        Confirm
                    </Button>
                </div>
            </div>
        </div>
    );
}