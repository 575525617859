import * as React from 'react';
import { FC, useEffect } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { RouteComponentProps } from 'react-router';
import { MetroSpinner } from "react-spinners-kit";
import * as AuthenticationStore from '../../../store/Account/AuthenticationStore';
import * as PublicStore from '../../../store/Common/PublicStore';
import * as LoaderStore from '../../../store/Common/LoaderStore';
import { AuthenticationType, ErrorState } from '../../../Core/Domain/ViewModels/PrimaryDetails';
import { AccessCodeLogin } from './components/AccessCodeLogin';
import { SSNLogin } from './components/SSNLogin';
import { QuestionAnswerLogin } from './components/QuestionAnswerLogin';
import { IClientSignatureStatus, SignatureDocumentStatus, SignatureStatus, StampingStatus } from '../../../Core/Domain/ViewModels/ClientSignatureStatus';
import { container } from '../../../Startup/inversify.config';
import { ILocalStore } from '../../../Core/Utilities/LocalStore';
import { TYPES } from '../../../Startup/types';
import { NotificationType } from '../../../Core/Common/Enums';

const localStore = container.get<ILocalStore>(TYPES.ILocalStore);

export type AuthenticationLoginProps = {
    userData: AuthenticationStore.ITokenData,
    publicData: PublicStore.IPublicData,
    loaderData: LoaderStore.ILoadingData
}
    & typeof AuthenticationStore.actionCreators
    & typeof PublicStore.actionCreators
    & RouteComponentProps<{}>;

const initialErrorState = {
    error: false,
    attemptsLeft: 3
};

export const AuthenticationLogin:FC <AuthenticationLoginProps> = (props) => {
    const [errorState, setErrorState] = React.useState<ErrorState>(initialErrorState);

    useEffect(() => {
        localStore.remove('loggedIn');
    },[]);

    const getAuthenticationContainer = () => {
        if (props.publicData.primaryDetails.authenticationInfo) {
            switch (props.publicData.primaryDetails.authenticationInfo.authenticationType) {
                case AuthenticationType.AccessCodeEmail:
                case AuthenticationType.AccessCodeSMS:
                    return <AccessCodeLogin
                        match={props.match}
                        validateParam={validateParam}
                        publicData={props.publicData}
                        requestAccessCode={props.requestAccessCode}
                        errorState={errorState}
                    />
                case AuthenticationType.First4SSN:
                case AuthenticationType.Last4SSN:
                    return <SSNLogin
                        publicData={props.publicData}
                        loaderData={props.loaderData}
                        validateParam={validateParam}
                        errorState={errorState}
                    />
                case AuthenticationType.QuestionAnswer:
                    return <QuestionAnswerLogin
                        publicData={props.publicData}
                        loaderData={props.loaderData}
                        validateParam={validateParam}
                        errorState={errorState}
                    />               
            }
        }
    }

    const validateParam = (authenticationParam: string) => {
        let param: any = props.match.params;
        props.validateParam(param.Id, authenticationParam, checkClientSignatureStatus);
    }

    const checkClientSignatureStatus = (result : any) => {
        if (result.Type === NotificationType.Success) {
            props.requestClientSignatureStatus(result.Data.ClientGuid, handleRedirect);
        }
        else if(result.Type === NotificationType.Warning && result.Message === "Invalid Login attempts. You can retry after 10 minutes"){
            if(errorState.attemptsLeft != 0){
                setErrorState({ error: true, attemptsLeft: 0 });
            }
        }
        else if(result.Type === NotificationType.Warning){
            if(errorState.attemptsLeft != 0){
                setErrorState({ error: true, attemptsLeft: errorState.attemptsLeft - 1 });
            }
        }
    }

    const handleRedirect = (status: IClientSignatureStatus, clientId: any, ignoreStatus: boolean) => {

        if (!ignoreStatus && status == undefined
            || (Object.keys(status).length === 0 && status.constructor === Object)
            || (status && (status && (status.documentStatus === SignatureDocumentStatus.Deleted ||
                status.documentStatus === SignatureDocumentStatus.Recycled ||
                status.signatureStatus === SignatureStatus.SignatureStampingFailed ||
                status.signatureStatus === SignatureStatus.Processing ||
                status.signatureStatus === SignatureStatus.DeliveryFailed ||
                status.signatureStatus === SignatureStatus.None)))
        ) {
            localStore.remove('loggedIn');
            props.history.push('/invalid');
        }
        else if (status) {
            if (status.signatureStatus === SignatureStatus.Cancelled) {
                props.history.push('/cancelled/' + clientId);
            }
            else if (status.signatureStatus === SignatureStatus.Declined) {
                props.history.push('/declined/' + clientId);
            }
            else if (status.isExpired) {
                props.history.push('/expired/' + clientId);
            }
            else if (status.isSigned || status.isAllSigned ||
                (status.hasDelegateeSigned != null && status.hasDelegateeSigned) ||
                status.signatureStatus === SignatureStatus.ESigned) {
                props.history.push('/download/' + clientId);
            }
            else {
                if (status.stampingStatus === StampingStatus.Processing ||
                    status.stampingStatus === StampingStatus.Failed) {
                    props.history.push('/signingprogress');
                }
                else
                    props.history.push('/esign/' + clientId);
            }
            history.pushState(null, "", location.href);
            window.onpopstate = () => {
                if(window.navigator && window.navigator.userAgent.match('CriOS') && !location.pathname.match("signingsuccess")){
                    location.reload();
                }
                else{
                    history.go(1);
                }
                document.title=("SafeSend Signatures")
            }
        }
    }

    return (
        <LoadingOverlay
            active={props.loaderData.loading}
            spinner={<MetroSpinner
                size={60} color="#3bbaea"
                loading={props.loaderData.loading} />}
        >
            <div id="authentication" className="authentication-page" >
                {getAuthenticationContainer()}
            </div>

        </LoadingOverlay>
    );
}