import * as React from 'react';


interface StampingState {

}

export class StampingNotComplete extends React.Component<{}, StampingState> {

    constructor(props: any, states: StampingState) {
		super(props, states);

	}

	public render() {
		return <div>

			<div id="content-wrapper" className="col-xs-12">
				<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-page">
					<div className="greeting text-center">
						We are preparing your signed document. To download a copy, return to your summary page shortly.
                    </div>
				</div>
			</div>
		</div>
	}
}
