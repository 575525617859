import { IDocument } from 'src/Core/Domain/ViewModels/Document';
import * as React from 'react';
import CheckboxTree from 'react-checkbox-tree';
import { DocumentType } from './FileListBase';
import { PdfViewManager } from '../../Common/PdfView/PdfViewBase';

export interface FileListProps {
    selectedDocumentId: number;
    documents: IDocument[];
    onFileClick(document: IDocument): void;
}

export interface FileListState {
    expanded: string[],
    nodes: any[]
}

export class FileList extends React.Component<FileListProps, FileListState> {


    constructor(props: any) {
        super(props);

        this.state = {
            expanded: [],
            nodes: []
        };

    }

    componentDidUpdate() {

        PdfViewManager.highlightSelectedFile(this.props.selectedDocumentId);

        if (this.state.nodes.length <= 0) {

            const distinctDocumentTypes: DocumentType[] = this.getDistinctDoumentTyps();

            this.setState({
                expanded: this.createExpandedNodes(distinctDocumentTypes),
                nodes: this.createTreeNodes(distinctDocumentTypes)
            });
        }
    }

    shouldComponentUpdate(nextProps: FileListProps, nextState: FileListState) {

        if (nextProps.documents.length > 0 && (this.props.documents.length !== nextProps.documents.length
            || this.state.nodes.length !== nextState.nodes.length || this.state.expanded.length !== nextState.expanded.length)
            || this.props.selectedDocumentId !== nextProps.selectedDocumentId) {
            return true;
        }

        return false;
    }


    onExpand = (expanded: any) => {
        this.setState({ expanded });
    }


    private getDistinctDoumentTyps = (): DocumentType[] => {

        let distinct: DocumentType[] = []

        for (var i = 0; i <= this.props.documents.length - 1; i++) {

            if (distinct.findIndex(x => x.id == this.props.documents[i].documentType.id) < 0) {
                distinct.push(this.props.documents[i].documentType);
            }
        }

        return distinct;
    }

    static getNodeLabel = (lable: string) => {
		return <span title={lable}> {lable} </span>;
	}

    private createTreeNodes = (documentTypes: DocumentType[]): any[] => {

        let nodes: any[] = [];

        for (var i = 0; i <= documentTypes.length - 1; i++) {

            const tmpDocumentType: DocumentType = documentTypes[i];
            const tmpDocuments: IDocument[] = this.props.documents.filter(x => x.documentType.id == tmpDocumentType.id);

            let tmpNode: any = {              
                value: `/${tmpDocumentType.id}`,
                label: tmpDocumentType.name, //tmpDocumentType.name.length < FileListStrings.ENGAGEMENT_TYPE_TEXT_LIMIT ? tmpDocumentType.name : tmpDocumentType.name.slice(0, FileListStrings.ENGAGEMENT_TYPE_TEXT_LIMIT) + "...",
                showcheckbox: true,
                title: tmpDocumentType.name,
                children: []
            };

            for (var j = 0; j <= tmpDocuments.length - 1; j++) {

                const tmpDocument: IDocument = tmpDocuments[j];

                tmpNode.children.push({
                    disabled: tmpDocument.disabled,
                    value: tmpDocument.id,
                    label: FileList.getNodeLabel(tmpDocument.name), //.length < FileListStrings.FILE_NAME_TEXT_LIMIT ? tmpDocument.name : tmpDocument.name.slice(0, FileListStrings.FILE_NAME_TEXT_LIMIT) + "...",
                    showcheckbox: true,
                    icon: this.getPdfIcon(),
                    title: tmpDocument.name,
                    className: "file-node-" + tmpDocument.id                    
                });
            }

            nodes.push(tmpNode);

        }

        return nodes;
    }

    private getPdfIcon() {
        return <i className="fa fa-file-pdf-o" />
    }

    private createExpandedNodes = (documentTypes: DocumentType[]): any[] => {

        let expandedNodes: string[] = [];

        for (var i = 0; i <= documentTypes.length - 1; i++) {
            expandedNodes.push(`/${documentTypes[i].id.toString()}`);

        }

        return expandedNodes;
    }


    private handleFileClick = (node: any) => {

        if (node.children == undefined) {

            const tmpDocument = this.props.documents.find(x => x.id == node.value && x.disabled == false);
            tmpDocument && this.props.onFileClick(tmpDocument);

        }
    }

    public render() {

        const { nodes, expanded } = this.state;

        return (<div className="file-list-tree-view-container">

            <CheckboxTree
                showExpandAll={false}
                expanded={expanded}
                iconsClass="fa5"
                nodes={nodes}
                expandOnClick
                onClick={this.handleFileClick}
                onExpand={this.onExpand}
            />
        </div>);

    }
}
