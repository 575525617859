import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import * as PublicStore from '../../../store/Common/PublicStore';
import LoadingOverlay from 'react-loading-overlay';
import { MetroSpinner } from "react-spinners-kit";
import * as LoaderStore from '../../../store/Common/LoaderStore';
import { BigCheckCircleIcon } from '../../Svg/CustomSvgIcons';

type LogoutSuccessProps =
    {
        publicData: PublicStore.IPublicData,
        loaderData: LoaderStore.ILoadingData
    }
    & typeof PublicStore.actionCreators
    & RouteComponentProps<{}>

export class LogoutSuccess extends React.Component<LogoutSuccessProps, {}> {   

    public render() {
        return <LoadingOverlay
            active={this.props.loaderData.loading}
            spinner={<MetroSpinner size={60} color="#3bbaea" loading={this.props.loaderData.loading} />}>            
            <div id="content-wrapper"  className="logoutSuccessContainer">
                <div className='logoutSuccessBox'>
                    <BigCheckCircleIcon/>
                    <div  className='font20 fontWeight700' style={{marginTop:'3%'}}>You've successfully logged off</div>
                    <div className='font16 fontWeight400' style={{marginTop:'1%'}}>For security purposes, we recommend closing your browser window.</div>
                </div>
            </div>
        </LoadingOverlay>
    }
}
