export class ControlHelper {
    public static readonly defaultSignatureControlWidth = 99;
    public static readonly defaultSignatureControlHeight = 20;
    public static readonly defaultInitialControlWidth = 99;
    public static readonly defaultInitialControlHeight = 20;
    public static readonly defaultDateControlWidth = 99;
    public static readonly defaultDateControlHeight = 20;
    public static readonly defaultNameAndTitleControlWidth = 120;
    public static readonly defaultNameAndTitleControlHeight = 20;
    public static readonly defaultNameControlWidth = 120;
    public static readonly defaultNameControlHeight = 20;
    public static readonly defaultTitleControlWidth = 120;
    public static readonly defaultTitleControlHeight = 20;
    public static readonly defaultCompanyControlWidth = 120;
    public static readonly defaultCompanyControlHeight = 20;
    public static readonly defaultCustomTextControlWidth = 120;
    public static readonly defaultCustomTextControlHeight = 20;
    public static readonly defaultCheckBoxControlWidth = 20;
    public static readonly defaultCheckBoxControlHeight = 20;
    public static readonly defaultNamePrintNameWidth = 120;
    public static readonly defaultPrintNameControlHeight = 20;
    public static readonly defaultRadioControlWidth = 20;
    public static readonly defaultRadioControlHeight = 20;

    public static readonly  pixelAdjustmentTop = 8;
}