import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import * as PublicStore from '../../store/Common/PublicStore';
import LoadingOverlay from 'react-loading-overlay';
import { MetroSpinner } from "react-spinners-kit";
import * as LoaderStore from '../../store/Common/LoaderStore';
import { BigCheckCircleIcon } from '../Svg/CustomSvgIcons';

type SigningSuccessProps =
    {
        publicData: PublicStore.IPublicData,
        loaderData: LoaderStore.ILoadingData
    }
    & typeof PublicStore.actionCreators
    & RouteComponentProps<{}>

export class SigningSuccess extends React.Component<SigningSuccessProps, {}> {

    public render() {
        return <LoadingOverlay
            active={this.props.loaderData.loading}
            spinner={<MetroSpinner size={60} color="#3bbaea" loading={this.props.loaderData.loading} />}>
            <div id="content-wrapper"  className="signingSuccessContainer">
                <div className='signingSuccessBox'>
                    <BigCheckCircleIcon/>
                    <div  className='font20 fontWeight700' style={{marginTop:'3%'}}>Signing Completed</div>
                    <div className='font16 fontWeight400' style={{marginTop:'5%'}}>You have successfully signed the <br/> document(s). A copy will be emailed to <br/> you once everyone has signed.</div>
                </div>
            </div>
        </LoadingOverlay>
    }
}
