import * as React from 'react';
import { FC , useState, useEffect} from 'react';
import { Button } from 'react-bootstrap';
import { match } from 'react-router';
import { AuthenticationType, ErrorState } from '../../../../Core/Domain/ViewModels/PrimaryDetails';
import { SiriusNotifier } from '../../../Common/Notification/SiriusNotifier';
import { IPublicData } from '../../../../store/Common/PublicStore';
import { InfoIconBig, PasswordLockIcon } from '../../../Svg/CustomSvgIcons';
import OtpInputControl from './OtpInputControl';
import { ButtonComponent } from 'cp-common-ui-components';


const accessCodeLength = 8;

export type AccessCodeLoginProps = {
    match: match;
    publicData: IPublicData,
    requestAccessCode: (id: string,callback?: any) => void;
    validateParam: (param: string) => void;
    errorState: ErrorState;
}

export const AccessCodeLogin:FC<AccessCodeLoginProps> = (props) => {

    const [accessCode, setAccessCode] = useState('');
    const [disableContinueButton, setDisableContinueButton] = useState(true);
    const [otpSent, setOtpSent] = useState(false);

    useEffect(() => {
        validateAccessCode();
    },[accessCode]);

    const onRequestAccessCodeCallback = (value: boolean) => {
        setOtpSent(value);
    }

    const onRequestAccessCode = () => {
        let param: any = props.match.params;
        props.requestAccessCode(param.Id, !otpSent ? onRequestAccessCodeCallback : undefined);
    }

    const validateAccessCode = () => {
        let disableContinueButtonValue = accessCode && Number(accessCode) &&
            accessCode.length == accessCodeLength ? false : true;
         
        setDisableContinueButton(disableContinueButtonValue);
    }

    const onContinue = () => {
        if (accessCode && parseInt(accessCode)) {
            if (accessCode.length != accessCodeLength) {
                SiriusNotifier.Warning("Please enter 8 digit access code", "")
            }
            else {
                props.validateParam(accessCode);
            }
        }
        else {
            SiriusNotifier.Warning("Please enter a valid access code", "")
        }
    }

    const isMobileAccessCode = props.publicData.primaryDetails.authenticationInfo.authenticationType === AuthenticationType.AccessCodeSMS ? true : false; 

    return (
        <div className="access-code-login-container">
            <div className='access-code-box'>
                <div> <PasswordLockIcon/> </div>
                <div className='font24 fontWeight500' style={{marginTop:'5%'}}>Request Access Code </div>

                {isMobileAccessCode ? 
                     (otpSent ?
                     <div className='font16' style={{marginTop:'3%'}}>
                        A One-Time Access Code has been sent <br/>
                        to your mobile number, <span className='fontWeight700'>{props.publicData.primaryDetails.authenticationInfo.maskedMobileNumber}</span>
                    </div> 
                    :
                     <div className='font16' style={{marginTop:'3%'}}>
                        We will send you a One-Time Access Code <br/>
                        to your mobile number, <span className='fontWeight700'>{props.publicData.primaryDetails.authenticationInfo.maskedMobileNumber}</span>
                    </div>)
                    : 
                     (otpSent ? 
                     <div className='font16'>
                            A One-Time Access Code has been sent <br/> to your email address.
                        </div>
                        : 
                        <div className='font16'>
                            We will send you a One-Time Access Code <br/> to your email address.
                        </div>)
                }
                   
                { otpSent ? 
                    <div>
                        <div style={{marginTop:'3%'}} className='error-text-color font16'> Expires in 20 minutes.</div> 
                        <div className='input-container'>
                            <OtpInputControl
                            otp={accessCode}
                            data-test-auto="614B3403-ED0C-47C1-A6D5-C3D4EAA46B66"
                            setOtp={(otp:string) => setAccessCode(otp)}
                            isInvalid={props.errorState.error}
                            />
                            {props.errorState.error ? <span className='error-svg'><InfoIconBig fill='#CC4A43'/></span> : null}
                         </div>
                        {props.errorState.error ?<div className='error-text-color font14' style={{marginTop:'3%',textAlign: 'left'}}>Incorrect code! You have {props.errorState.attemptsLeft} more attempts left.</div> : null}
                        <div style={{marginTop:'8%'}} className='font14'>If you did not receive it yet, check your junk/spam.</div>
                        <div className='resendCodeButton'>
                            <ButtonComponent variant={"link"}
                            size={"small"} buttonClassName='font14 black-text-color fontWeight700' onClick={onRequestAccessCode}>
                                Resend Code
                            </ButtonComponent>
                        </div>
                        <div className='access-code-button'>
                            <Button
                               data-test-auto="35837F9F-F4A4-4318-9105-68E4D9B2BD90"
                               disabled={disableContinueButton}
                               onClick={onContinue}>
                                Confirm
                            </Button>
                        </div>
                    </div> 
                    :
                    <div className='access-code-button'>
                        <Button
                            id="linkResendOTP"
                            type="button"
                            onClick={onRequestAccessCode}
                            data-test-auto="03781666-76C1-4AB8-8BF8-B80F0A59153C">
                            Send Code
                        </Button>
                    </div>
                }
                {isMobileAccessCode ? <div className='font14 fontWeight$00' style={{color:'#565A5E', marginTop: '10%'}}>If the mobile number is incorrect, please contact your Tax Professional.</div> : null}    
            </div>
        </div>
  );
};