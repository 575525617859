import { ClientTypes } from '../../Common/Enums';

export interface IClientSignatureStatus {
    signingId: number,
    clientGuid: string,
    signatureStatus: SignatureStatus,
    isExpired: boolean,
    isAllSigned: boolean,
    isSigned: boolean,
    declinedBy: string,
    declinedRemarks: string,
    declinedOn?: Date,
    cancelledByName: string,
    cancelledByEmailAddress: string,
    cancelledRemarks: string,
    cancelledOn?: Date,
    senderFirstName: string,
    senderLastName: string,
    senderEmailAddress: string,
    companyName: string,
    sentOnBehalfOfFirm: boolean,
    senderName: string,
    clientType: ClientTypes,
    delegateeId?: number,
    hasDelegateeSigned?: boolean,
    isMigratedSigningIdForAudit: boolean,
    stampingStatus: StampingStatus,
    documentStatus: SignatureDocumentStatus
}

export enum SignatureStatus {
    None = 0,
    Processing = 1,
    //Signing not completed
    OutForDelivery = 2,
    //All E-signed
    ESigned = 3,
    //Signer declined from TP Side
    Declined = 4,
    Cancelled = 5,
    PartiallySigned = 6,
    SignatureStampingFailed = 7,
    DeliveryFailed = 8
}

export enum StampingStatus {
    None = 0,
    Processing = 1,
    Completed = 2,
    Failed = 3
}
export enum SignatureDocumentStatus {
    Active = 1,
    Archived = 2,
    Recycled = 3,
    Deleted = 4,

}

export const initialClientSignatureStatus: Readonly<IClientSignatureStatus> = {
    cancelledByEmailAddress: "",
    cancelledByName: "",
    cancelledRemarks: "",
    clientGuid: "",
    companyName: "",
    declinedBy: "",
    declinedRemarks: "",
    isAllSigned: false,
    isExpired: false,
    isSigned: false,
    senderEmailAddress: "",
    senderFirstName: "",
    senderLastName: "",
    signatureStatus: SignatureStatus.None,
    signingId: 0,
    sentOnBehalfOfFirm: false,
    senderName: "",
    clientType: ClientTypes.Undefined,
    delegateeId: 0,
    isMigratedSigningIdForAudit: false,
    stampingStatus: StampingStatus.None,
    documentStatus: SignatureDocumentStatus.Active
}

