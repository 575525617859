import { ICompanyModel } from './ICompanyModel';

export interface IPrimaryDetails {
    companyData: ICompanyModel;
    IsOtpRequired: boolean;
    isMobileView: boolean;
    senderName: string;
    sender: IUserModel;
    clientName: string;
    loggedInClient: string;
    authenticationInfo: IAuthenticationInfo;
    signerMobileUrl: string;
    signerAllowedToDelegate : boolean;
    contactPerson: IUserModel;
}

export interface IUserModel {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    countryCode: string;
    email: string;
    mobileNumber: string;
}

export enum AuthenticationType {
    None = 0,
    First4SSN = 1,
    Last4SSN = 2,
    AccessCodeEmail = 3,
    AccessCodeSMS = 4,
    QuestionAnswer = 5
}

export interface IAuthenticationInfo {
    email: string;
    authenticationType: AuthenticationType;
    maskedMobileNumber: string;
    question: string;
    isLocked: boolean;
    lockedOn: Date;
}

export interface ICountryState {
    id: number;
    name: string;
    image: string;
}

export interface ErrorState{
    error: boolean;
    attemptsLeft: number;
}
