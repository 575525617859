import { IControl } from "../Models/Controls";
import { DocumentType } from "./DocumentViewModel";


export interface IDocument {

    id: number;
    name: string;
    url: string;
    disabled: boolean;
    documentType: DocumentType;
    pages: IPage[];

}

export class Document implements IDocument {

    id: number;
    name: string;
    url: string;
    disabled: boolean;
    documentType: DocumentType;
    pages: IPage[];

    constructor(id: number, name: string, url: string, disabled: boolean, documentType: DocumentType, pages: IPage[]) {

        this.id = id;
        this.name = name;
        this.url = url;
        this.disabled = disabled;
        this.documentType = documentType;
        this.pages = pages;
    }


    public static create(id: number, name: string, url: string, disabled: boolean, documentType: DocumentType, pages: IPage[]): IDocument {
        return new Document(id, name, url, disabled, documentType, pages);
    }

    public static createNullObject(): IDocument {
        return new Document(0, "", "", false, DocumentType.createNullObject(), []);
    }
}


export interface IPage {
    page: number;
    controls: IControl[];
}

export class Page implements IPage {
    page: number;
    controls: IControl[];


    constructor(page: number, controls: IControl[]) {

        this.page = page;
        this.controls = controls;

    }

    public static create(page: number, controls: IControl[]): IPage {
        return new Page(page, controls);
    }

}


