import * as React from 'react';
import { NotificationState, StatusType } from "../../../store/Common/NotificationStore";
import { SiriusNotifier } from './SiriusNotifier';

type NotificationProps =
    {
        notification: NotificationState
    }

export class Notification extends React.Component<NotificationProps, any> {

    componentWillReceiveProps(nextProps: NotificationProps) {
        if (nextProps.notification.type == StatusType.Success) {
            SiriusNotifier.Success(nextProps.notification.message, null);
        }
        else if (nextProps.notification.type == StatusType.Error) {
            SiriusNotifier.Error(nextProps.notification.message, null);
        }
        else if (nextProps.notification.type == StatusType.Warning) {
            SiriusNotifier.Warning(nextProps.notification.message, null);
        }
        else {
            SiriusNotifier.Info(nextProps.notification.message, null);
        }
    }
    render() {
        return (<div />);
    }
}