import { ApplicationState } from "../../store/index";
import { RouteSelector } from "./RouteSelector";
import { connect } from 'react-redux';
import { actionCreators as OtpStore } from '../../store/Account/OtpStore';
import { actionCreators as AuthenticationStore } from '../../store/Account/AuthenticationStore';
import { actionCreators as PublicStore } from '../../store/Common/PublicStore';
import { IClientSignatureStatus } from "../../../src/Core/Domain/ViewModels/ClientSignatureStatus";

function mapStateToProps(state: ApplicationState) {
    return {
        publicUserToken: state.publicUserToken,
        publicData: state.publicData,
        userData: state.userData,
        loaderData: state.loaderData
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        requestPrimaryDetails: (Id: string,callback?: () => void) => dispatch(PublicStore.requestPrimaryDetails(Id,callback)),
        getTokenForAnonymous: (Id: string, callback: (clientId: string) => void) => dispatch(PublicStore.getTokenForAnonymous(Id, callback)),
        requestAccessCode: (Id: string, callback?: any ) => dispatch(AuthenticationStore.requestAccessCode(Id,callback)),
        validateParam: (Id: string, param: string, callback: any) => dispatch(AuthenticationStore.validateParam(Id, param, callback)),
        refreshToken: (clientId: string, callback?: () => void) => dispatch(AuthenticationStore.refreshToken(clientId, callback)),
        dispatchUserToken: (clientId: string) => dispatch(PublicStore.dispatchUserToken(clientId)),
        requestClientSignatureStatus: (clientGuid: string, callback: (status: IClientSignatureStatus, clientId: any, ignoreStatus: boolean) => void) =>
            dispatch(AuthenticationStore.requestClientSignatureStatus(clientGuid, callback)),
        getAllStates: (Id: string) => dispatch(PublicStore.getAllStates(Id)),
        updateLockState: (isLocked: boolean) => dispatch(PublicStore.updateLockState(isLocked)),
        requestCompanyLogo: (Id: string) => dispatch(PublicStore.requestCompanyLogo(Id))
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(RouteSelector);
