export function parseJSON(response: any) {
    return response.text()
        .then((text: string) => {
            return text ? JSON.parse(text) : {}
        });
}

export function handleResponse(response: any) {
    if (response.ok) {
        return parseJSON(response.clone());
    }
    else {
        return response.json().catch((error: any) => {
            // the status was not ok and there was not custom error provided
            return Promise.reject({
                status: response.status,
                statusText: response.statusText,
            });
        }).then((json: any) => {
            // the status was not ok but custom error provided
            return Promise.reject({
                status: response.status,
                statusText: json,
            });
        });
    }
}

export function handleBlob(response: any) {
    if (response.ok) {
        return response.blob();
    }
    else {
        return response.json().catch((error: any) => {
            // the status was not ok and there was not custom error provided
            return Promise.reject({
                status: response.status,
                statusText: response.statusText,
            });
        }).then((json: any) => {
            // the status was not ok but custom error provided
            return Promise.reject({
                status: response.status,
                statusText: json,
            });
        });
    }
}