import { ApplicationState } from "../../../store/index";
import { LogoutSuccess } from "./LogoutSuccess";
import { connect } from 'react-redux';
import { actionCreators as PublicStore }  from '../../../store/Common/PublicStore';

function mapStateToProps(state: ApplicationState) {
    return {
        publicData: state.publicData,
        loaderData: state.loaderData
    };
}
//temperroy fix for webpack failure need to invetigate further when we have time
const mapDispatchToProps = (dispatch: any) => {
    return {
        requestPrimaryDetails: (Id: string,callback?: () => void) => dispatch(PublicStore.requestPrimaryDetails(Id,callback)),
        dispatch
    }
}


const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(LogoutSuccess);
 