import * as React from 'react';
import { Form, Button } from 'react-bootstrap';
import { useEffect, useState, FC } from 'react';
import { AuthenticationType, ErrorState } from '../../../../Core/Domain/ViewModels/PrimaryDetails';
import { IPublicData } from '../../../../store/Common/PublicStore';
import { ILoadingData } from '../../../../store/Common/LoaderStore';
import { InfoIconSmall, ProfileIcon } from '../../../Svg/CustomSvgIcons';


const ssnLength = 4;

export type SSNLoginProps = {
    publicData: IPublicData,
    loaderData: ILoadingData,
    validateParam: (param: string) => void;
    errorState: ErrorState;
}

export const SSNLogin:FC<SSNLoginProps> = (props) => {
    const [ssn, setSSN] = useState<string>('');
    const [disableContinueButton, setDisableContinueButton] = useState<boolean>(true);

    const isFirst4SSN = props.publicData.primaryDetails.authenticationInfo.authenticationType === AuthenticationType.First4SSN ? true : false; 
    const ssnString = isFirst4SSN ? "first four digits" : "last four digits";

    useEffect(() => {
        validateSSN();
    },[ssn]);


    const handleSSNChange = (event: any) => {
        let value = event.target.value;
        let numbers = value.replace(/[^0-9]/g, "");
        event.target.value = numbers;
        setSSN(event.target.value);
    }

    const validateSSN = () => {
        let disableContinueButtonValue = ssn && ssn.length == ssnLength ? false : true;
        setDisableContinueButton(disableContinueButtonValue)
    }

    const onContinue = () => {
            props.validateParam(ssn);
    }

    return (
        <div className='ssn-login-container'>
            <div className='ssn-login-box'>
                <div>
                    <ProfileIcon/>
                </div>

                <div className='font24 fontWeight700' style={{marginTop:'5%'}}>
                    Hello <span className='navy-blue-color' >{props.publicData.primaryDetails.clientName}</span>,
                </div>
                
                <label htmlFor='ssn-input' className='font16 fontWeight400' style={{marginTop:'3%'}}>
                    Please enter the {ssnString} of your <br/> Social Security Number or EIN.
                </label>
                
                <div className='ssn-input-box'>
                        <Form.Control
                            type="password"
                            maxLength={4}
                            id='ssn-input'
                            placeholder='4 digits'
                            className={`${props.errorState.error ? " error" : ""}`}
                            autoComplete={"off"}
                            onChange={handleSSNChange}
                            data-test-auto="55FE7CD6-694C-4B6B-A8C9-EDA249D4AACA"
                        />
                        {props.errorState.error ? 
                            <>
                                <span className='error-svg'><InfoIconSmall fill='#CC4A43'/></span>  
                            </>
                        : null}
                </div>       
                {props.errorState.error ? <div className='error-text-color font14' style={{marginTop:'2%',textAlign: 'left'}}>Incorrect SSN or EIN! You have {props.errorState.attemptsLeft} attempts left.</div> : null}

                <div className='ssn-button'>
                    <Button
                        type="button"
                        onClick={onContinue}
                        disabled={disableContinueButton}
                        data-test-auto="1BE9FAB7-CAA7-4BB5-99A7-D4A4FE5AFCAA">
                        Confirm
                    </Button>
                </div>
            </div>
        </div>
    );
};