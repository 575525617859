import { Action, Reducer } from 'redux';
import { actionTypes } from '../ActionTypes';

export interface ILoadingData {
    loading: boolean;
}

export type HttpAction = InitiateRequestAction | CompleteResponseAction;

interface InitiateRequestAction {
    type: actionTypes.INITIATE_REQUEST;
    loading: boolean;
}

interface CompleteResponseAction {
    type: actionTypes.COMPLETE_RESPONSE;
    loading: boolean;
}

type KnownAction = InitiateRequestAction | CompleteResponseAction;

const unloadedState: ILoadingData = {
    loading: false
} as ILoadingData;

export const tokenReducer: Reducer<ILoadingData> = (state: ILoadingData = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.INITIATE_REQUEST:
            return {
                loading : action.loading
            } as ILoadingData;
        case actionTypes.COMPLETE_RESPONSE:
            return {
                loading: action.loading
            } as ILoadingData;
        default:
            return state || unloadedState;
    }
};