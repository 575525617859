import { injectable } from "inversify";

export interface IUtilities {
	//    Animate(start: number, end: number, duration: number): void;
	formateFax(fax: string): string;
	isValidEmailAddress(emailAddress: string): boolean;
	getUrlPathLastComponent(path: string): string;
}

@injectable()
export class Utilities implements IUtilities {

	//    Animate(start: number, end: number, duration: number): void {

	//        let range: number = end - start;
	//        var current = start;
	//        var increment = end > start ? 1 : -1;
	//        var stepTime = Math.abs(Math.floor(duration / range));
	//        var timer = setInterval(function () {
	//            current += increment;
	//            obj.innerHTML = current;
	//            if (current == end) {
	//                clearInterval(timer);
	//            }
	//        }, stepTime);

	//    } 

	formateFax(fax: string): string {
		if (fax == undefined || (fax != undefined && fax.length != 10)) {
			console.log("Invalid Fax value");
			return "";
		}
		return "(" + fax.slice(0, 3) + ") " + fax.slice(3, 6) + "-" + fax.slice(6);
	}

	isValidEmailAddress(emailAddress: string): boolean {
		var pattern = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i);
		return pattern.test(emailAddress);
	}

	getUrlPathLastComponent(path: string): string {
		var pathComponents = path.split('/');
		return pathComponents[pathComponents.length - 1];
	}
}