import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, DistributedTracingModes, IEventTelemetry, ITelemetryItem, SeverityLevel, Util } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';
import { handleResponse } from "../../store/Library";
import { ILogger } from "../Logger";
import { SIGNER_BASE_URL } from "../../utils/AppConstants"

let profileData: any;

export class TelemetryLogger implements ILogger {

    private static instance: TelemetryLogger;
    private static appInsights: ApplicationInsights;
    private static pageViewRefTime: any;

    private constructor() {
        TelemetryLogger.appInsights = appInsights;
    }

    public static getInstance(): TelemetryLogger {
        if (!TelemetryLogger.instance) {
            this.instance = new TelemetryLogger();
        }
        return TelemetryLogger.instance;
    }

    public trackPageView = (pageName: string) => {
        TelemetryLogger.appInsights.context.telemetryTrace.name = pageName;
        TelemetryLogger.appInsights.context.telemetryTrace.traceID = Util.generateW3CId();
        TelemetryLogger.appInsights.trackPageView({ name: pageName, properties: { duration: new Date().getTime() - TelemetryLogger.pageViewRefTime } });
        TelemetryLogger.pageViewRefTime = new Date().getTime();
    }

    public trackTrace = (message: string) => {
        TelemetryLogger.appInsights.trackTrace(
            {
                message: message,
                severityLevel: SeverityLevel.Information
            }
        );
    }
    public trackError = (message: string) => {
        TelemetryLogger.appInsights.trackException(
            {
                exception: new Error(message)
            }
        );
    }
    public trackEvent = (traceEvent: IEventTelemetry) => {
        TelemetryLogger.appInsights.trackEvent(traceEvent);
    }
}

const getDataForAppInsigts = async () => {
    const response = await fetch(SIGNER_BASE_URL + 'api/ApplicationInsights', { credentials: 'include' });
    return handleResponse(response);
};

getDataForAppInsigts().then(response => {
    profileData = response;
    appInsights.config.instrumentationKey = profileData.instrumentationKey;
});

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: '',
        disableInstrumentationKeyValidation: true,
        disableFetchTracking: false,
        disableAjaxTracking: false,
        enableCorsCorrelation: true,
        distributedTracingMode: DistributedTracingModes.W3C,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        disableCorrelationHeaders: false,
        autoTrackPageVisitTime: true,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: createBrowserHistory({ basename: '' }) }
        }
    }
});
appInsights.loadAppInsights();

//Note: Middleware will be executed for every track event (PageView/Dependency/Trace/Exception/etc)
appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {

    envelope.tags = envelope.tags || [];
    envelope.tags.push({ "ai.cloud.role": "SIGSignerService-UI" });
    if (profileData !== undefined) {
        envelope.tags.push({ "ai.user.accountId": profileData.companyId });
        envelope.tags.push({ "ai.user.id": profileData.userId });
    }
    var telemetryItem = envelope.baseData;
    if (telemetryItem !== undefined && profileData !== undefined) {
        telemetryItem.properties = telemetryItem.properties || {};
        telemetryItem.properties["UserId"] = profileData.userId;
        telemetryItem.properties["CompanyId"] = profileData.companyId;
        telemetryItem.properties["CompanyName"] = profileData.companyName;
    }
});

export { reactPlugin };